import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IVideo } from '@guider-global/shared-types';
import videoSlice from 'store/slices/videoSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Video REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IVideo> hook
 * @returns ...hook - all return values via the `useRestRedux<IVideo>()` hook
 * @returns video - array of video in the RestRootState reducer
 * @returns reqVideo - makes requests and stores results in the videoReducer
 * @returns getErrorsVideo - gets API errors from the videoReducer
 */
export function useVideos({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IVideo, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IVideo, RestRootStateTypes> = useRestRedux<
    IVideo,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'video',
    actions: videoSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    video: hook.getResults,
    reqVideo: hook.request,
    getErrorsVideo: hook.getErrors,
    hasResultsVideo: hook.hasResults,
    getMessageVideo: hook.getMessage,
    getCodeVideo: hook.getCode,
    isLoadingVideo: hook.isLoading,
    isErrorVideo: hook.isError,
    isSuccessVideo: hook.isSuccess,
  };
}
