import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { UserIntegration } from '@guider-global/shared-types';
import userIntegrationsSlice from 'store/slices/userIntegrationsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle UserIntegrations REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<UserIntegration> hook
 * @returns ...hook - all return values via the `useRestRedux<UserIntegration>()` hook
 * @returns userIntegrations - array of userIntegrations in the RestRootState reducer
 * @returns reqUserIntegrations - makes requests and stores results in the userIntegrationsReducer
 * @returns getErrorsUserIntegrations - gets API errors from the userIntegrationsReducer
 */
export function useUserIntegrations({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<UserIntegration, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<UserIntegration, RestRootStateTypes> =
    useRestRedux<UserIntegration, RestRootState, RestRootStateTypes, RootState>(
      {
        ...args,
        resultDataName: 'userIntegrations',
        actions: userIntegrationsSlice.actions,
        waitForAuthentication,
        accessToken,
        onExpiredAccessToken: getAccessToken,
        traceId: sessionId ?? '',
      },
    );
  return {
    ...hook,
    userIntegrations: hook.getResults,
    reqUserIntegrations: hook.request,
    getErrorsUserIntegrations: hook.getErrors,
    hasResultsUserIntegrations: hook.hasResults,
    getMessageUserIntegrations: hook.getMessage,
    getCodeUserIntegrations: hook.getCode,
    isLoadingUserIntegrations: hook.isLoading,
    isErrorUserIntegrations: hook.isError,
    isSuccessUserIntegrations: hook.isSuccess,
  };
}
