import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IChatParticipant } from '@guider-global/shared-types';
import chatParticipantsSlice from 'store/slices/chatParticipantsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle ChatParticipants REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IRelationship> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns relationships - array of relationships in the RestRootState reducer
 * @returns reqChatParticipants - makes requests and stores results in the relationshipsReducer
 * @returns getErrorsChatParticipants - gets API errors from the relationshipsReducer
 */
export function useChatParticipants({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChatParticipant, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IChatParticipant, RestRootStateTypes> =
    useRestRedux<
      IChatParticipant,
      RestRootState,
      RestRootStateTypes,
      RootState
    >({
      ...args,
      resultDataName: 'chatParticipants',
      actions: chatParticipantsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    getChatParticipants: hook.getResults,
    reqChatParticipants: hook.request,
    getErrorsChatParticipants: hook.getErrors,
    hasResultsChatParticipants: hook.hasResults,
    getMessageChatParticipants: hook.getMessage,
    getCodeChatParticipants: hook.getCode,
    getIsLoadingChatParticipants: hook.isLoading,
    getIsErrorChatParticipants: hook.isError,
    getIsSuccessChatParticipants: hook.isSuccess,
  };
}
