import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IError, IMetric } from '@guider-global/shared-types';
import { useMetricsTokens } from 'hooks/useMetricsTokens';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import metricsSlice from 'store/slices/metricsSlice';

export function useMetrics({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMetric, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMetric, RestRootStateTypes> = useRestRedux<
    IMetric,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'metrics',
    actions: metricsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });

  const organizationSlug = getSubDomain();
  const [currentMetric, setCurrentMetric] = useState<IMetric>();
  const [embedUrl, setEmbedUrl] = useState<undefined | string>();
  const [metricsAccessToken, setMetricsAccessToken] = useState<
    undefined | string
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const metrics: IMetric[] = hook.getResults();

  const params = useParams();

  const selectedMetricName = useMemo(
    () => params?.metricName || currentMetric?.name,
    [params],
  );

  const { reqMetricsTokens, isErrorMetricsTokens, getErrorsMetricsTokens } =
    useMetricsTokens({
      getSilently: false,
      waitForAuthentication,
    });

  const getToken = useCallback(async (metric: IMetric) => {
    setIsLoading(true);
    const { name, type } = metric;
    const result = await reqMetricsTokens({
      method: 'POST',
      url: '/metrics/token',
      data: { name, type, organizationSlug },
    });
    const { status, data, errors } = result;
    if (status === 'success' && data !== null && data.length > 0) {
      const { token } = data[0];
      setMetricsAccessToken(token);
      setIsLoading(false);
    } else {
      console.log('Error getting metrics access token!', { errors });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      (selectedMetricName && !currentMetric) ||
      selectedMetricName !== currentMetric?.name
    ) {
      const metric: IMetric | undefined = metrics.find(
        (metric) => metric.name === selectedMetricName,
      );
      if (metric) {
        setCurrentMetric(metric);
        getToken(metric as IMetric);

        if (metric?.type === 'dashboard') {
          setEmbedUrl(metric.embedUrl);
        }
      }
    }
  }, [selectedMetricName, currentMetric]);

  const hasMetricPermissions = useMemo(() => metrics.length > 0, [metrics]);

  function getErrorsMetrics() {
    const errors: IError[] = [...getErrorsMetricsTokens(), ...hook.getErrors()];
    return errors;
  }

  function isErrorMetrics() {
    if (hook.isError()) {
      return true;
    } else if (isErrorMetricsTokens()) {
      return true;
    } else {
      return false;
    }
  }

  return {
    ...hook,
    hasMetricPermissions,
    metricsAccessToken,
    selectedMetricName,
    currentMetric,
    embedUrl,
    metrics: hook.getResults,
    reqMetrics: hook.request,
    getErrorsMetrics: getErrorsMetrics,
    hasResultsMetrics: hook.hasResults,
    getMessageMetrics: hook.getMessage,
    getCodeMetrics: hook.getCode,
    isLoadingMetrics: isLoading,
    isErrorMetrics: isErrorMetrics,
    isSuccessMetrics: hook.isSuccess,
  };
}
