import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { INote } from '@guider-global/shared-types';
import notesSlice from 'store/slices/notesSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Notes REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<INote> hook
 * @returns ...hook - all return values via the `useRestRedux<INote>()` hook
 * @returns notes - array of notes in the RestRootState reducer
 * @returns reqNotes - makes requests and stores results in the notesReducer
 * @returns getErrorsNotes - gets API errors from the notesReducer
 */
export function useNotes({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<INote, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<INote, RestRootStateTypes> = useRestRedux<
    INote,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'notes',
    actions: notesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    notes: hook.getResults,
    reqNotes: hook.request,
    getErrorsNotes: hook.getErrors,
    hasResultsNotes: hook.hasResults,
    getMessageNotes: hook.getMessage,
    getCodeNotes: hook.getCode,
    isLoadingNotes: hook.isLoading,
    isErrorNotes: hook.isError,
    isSuccessNotes: hook.isSuccess,
  };
}
