import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import {
  DayOfWeekAvailabilities,
  DaysOfWeek,
  IAvailability,
} from '@guider-global/shared-types';
import availabilitiesSlice from 'store/slices/availabilitiesSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useMemo } from 'react';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useSanityOrganization } from '@guider-global/sanity-hooks';
import { useDatadogContext } from '@guider-global/datadog';

const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '18:00';

/**
 * A custom hook to handle Availabilities REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IAvailability> hook
 * @returns ...hook - all return values via the `useRestRedux<IAvailability>()` hook
 * @returns availabilities - array of availabilities in the RestRootState reducer
 * @returns reqAvailabilities - makes requests and stores results in the availabilitiesReducer
 * @returns getErrorsAvailabilities - gets API errors from the availabilitiesReducer
 */
export function useAvailabilities({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IAvailability, RestRootState, RootState>) {
  const organizationSlug = getSubDomain();
  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const organization = getOrganization();
  const orgStartTime = organization?.availabilities?.start_time;
  const orgEndTime = organization?.availabilities?.end_time;

  const defaultAvailability = useMemo(
    () =>
      DaysOfWeek.reduce((acc, day) => {
        const isNotWeekend = day !== 'saturday' && day !== 'sunday';
        const startTime = orgStartTime ?? DEFAULT_START_TIME;
        const endTime = orgEndTime ?? DEFAULT_END_TIME;

        acc[`${day}Enabled`] = isNotWeekend;
        acc[`${day}Start`] = isNotWeekend ? startTime : '';
        acc[`${day}End`] = isNotWeekend ? endTime : '';

        return acc;
      }, {} as DayOfWeekAvailabilities),
    [orgStartTime, orgEndTime],
  );

  // Auth
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();

  const hook: IUseRestReduxResult<IAvailability, RestRootStateTypes> =
    useRestRedux<IAvailability, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'availabilities',
      actions: availabilitiesSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });

  const createAvailabilities = (data: Record<string, any>) =>
    hook.request({
      method: 'POST',
      url: `/availabilities`,
      data,
    });

  const updateAvailabilities = (
    availabilityId: string,
    data: Record<string, any>,
  ) =>
    hook.request({
      method: 'PATCH',
      url: `/availabilities/${availabilityId}`,
      data,
    });

  return {
    ...hook,
    defaultAvailability,
    availabilities: hook.getResults,
    reqAvailabilities: hook.request,
    createAvailabilities,
    updateAvailabilities,
    getErrorsAvailabilities: hook.getErrors,
    hasResultsAvailabilities: hook.hasResults,
    getMessageAvailabilities: hook.getMessage,
    getCodeAvailabilities: hook.getCode,
    isLoadingAvailabilities: hook.isLoading,
    isErrorAvailabilities: hook.isError,
    isSuccessAvailabilities: hook.isSuccess,
  };
}
