import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import leaderboardPositionsSlice from 'store/slices/leaderboardPositionsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { ILeaderboardPosition } from '@guider-global/shared-types';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle LeaderboardPositions REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IRelationship> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns leaderboardPositions - array of LeaderboardPositions in the RestRootState reducer
 * @returns reqLeaderboardPositions - makes requests and stores results in the LeaderboardPositionsReducer
 * @returns getErrorsLeaderboardPositions - gets API errors from the LeaderboardPositionsReducer
 */
export function useLeaderboardPositions({
  ...args
}: RestReduxHook<ILeaderboardPosition, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication: true,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ILeaderboardPosition, RestRootStateTypes> =
    useRestRedux<
      ILeaderboardPosition,
      RestRootState,
      RestRootStateTypes,
      RootState
    >({
      ...args,
      resultDataName: 'leaderboardPositions',
      actions: leaderboardPositionsSlice.actions,
      waitForAuthentication: true,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    leaderboardPositions: hook.getResults,
    reqLeaderboardPositions: hook.request,
    getErrorsLeaderboardPositions: hook.getErrors,
    hasResultsLeaderboardPositions: hook.hasResults,
    getMessageLeaderboardPositions: hook.getMessage,
    getCodeLeaderboardPositions: hook.getCode,
    isLoadingLeaderboardPositions: hook.isLoading,
    isErrorLeaderboardPositions: hook.isError,
    isSuccessLeaderboardPositions: hook.isSuccess,
  };
}
