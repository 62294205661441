import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { interpolate } from 'functions';
import { useCallback } from 'react';

type GetValidationErrorBlank = (fieldName: string) => string;

type UseValidationErrorBlank = {
  getValidationErrorBlank: GetValidationErrorBlank;
};

/**
A custom hook to get interpolation to profile fields
 */

export function useValidationErrorBlank(): UseValidationErrorBlank {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const getValidationErrorBlank: GetValidationErrorBlank = useCallback(
    (fieldName) => {
      if (!baseLanguage || !fieldName) {
        return '';
      }

      return interpolate(baseLanguage.globals.errors.validation_error_blank, {
        fieldName,
      });
    },
    [baseLanguage],
  );

  return { getValidationErrorBlank };
}
