import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMetricToken } from '@guider-global/shared-types';
import metricsTokensSlice from 'store/slices/metricsTokensSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle MetricsTokens REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IMetricsToken> hook
 * @returns ...hook - all return values via the `useRestRedux<IMetricsToken>()` hook
 * @returns metricsTokens - array of metricsTokens in the RestRootState reducer
 * @returns reqMetricsTokens - makes requests and stores results in the metricsTokensReducer
 * @returns getErrorsMetricsTokens - gets API errors from the metricsTokensReducer
 */
export function useMetricsTokens({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMetricToken, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMetricToken, RestRootStateTypes> =
    useRestRedux<IMetricToken, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'metricsTokens',
      actions: metricsTokensSlice.actions,
      getSilentlyUrl: `/metrics/token`,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    metricsTokens: hook.getResults,
    reqMetricsTokens: hook.request,
    getErrorsMetricsTokens: hook.getErrors,
    hasResultsMetricsTokens: hook.hasResults,
    getMessageMetricsTokens: hook.getMessage,
    getCodeMetricsTokens: hook.getCode,
    isLoadingMetricsTokens: hook.isLoading,
    isErrorMetricsTokens: hook.isError,
    isSuccessMetricsTokens: hook.isSuccess,
  };
}
