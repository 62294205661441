import { datadogLogs } from '@guider-global/datadog';
import { setDocLangAttrAndMetaTag } from '@guider-global/front-end-utils';
import { useSanityLocalization } from '@guider-global/sanity-hooks';
import { SessionStorageKeys, useProfiles } from 'hooks';
import { useSettings } from 'hooks/useSettings';
import { useCallback, useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

interface UseLocalizationResults {
  localeCode?: string;
  handleLanguageChange: (localeCode: string) => Promise<void>;
}

export const useLocalization = (
  organizationSlug: string,
): UseLocalizationResults => {
  const dispatch = useAppDispatch();

  // Profile
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();

  // Setting
  const {
    getIsLoadingSettings,
    getSettings,
    updateSettings,
    createSettings,
    getIsErrorSettings,
    getErrorsSettings,
  } = useSettings({
    getSilently: true,
  });
  const isLoadingSettings = getIsLoadingSettings();
  const isErrorSettings = getIsErrorSettings();
  const [settings] = getSettings();

  const lastUsedLanguage = settings?.localeCode;

  const createOrUpdateSettings = useCallback(
    (localeCode: string) => {
      if (settings) {
        return updateSettings(settings.id, { localeCode });
      } else if (profile) {
        return createSettings({ profile: profile.id, localeCode });
      }
    },
    [settings, profile, createSettings, updateSettings],
  );

  const onLanguageChange = useCallback(
    async (localeCode: string) => {
      await createOrUpdateSettings(localeCode);
      setDocLangAttrAndMetaTag(localeCode);
    },
    [createOrUpdateSettings],
  );

  useEffect(() => {
    const handleShowAppError = (errorCode?: string, errorMessage?: string) => {
      console.error('error', { errorCode, errorMessage });
      datadogLogs.logger.error('useLocalization:', {
        errorCode,
        errorMessage,
      });
      dispatch(
        showAppAlert({
          severity: 'error',
          message: errorMessage,
          timeout: 10000,
        }),
      );
    };

    if (isErrorSettings) {
      const settingErrors = getErrorsSettings();
      const errorCodes = `${settingErrors.map((error) => error.code)}`;
      const errorMessages = `${settingErrors.map((error) => error.message)}`;
      handleShowAppError(errorCodes, errorMessages);
    }
  }, [dispatch, getErrorsSettings, isErrorSettings]);

  const { localeCode, handleLanguageChange } = useSanityLocalization<RootState>(
    organizationSlug,
    SessionStorageKeys,
    onLanguageChange,
    isLoadingSettings,
    lastUsedLanguage,
  );

  return {
    localeCode,
    handleLanguageChange,
  };
};
