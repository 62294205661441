import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IEvent, IGoal, Program } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import eventsSlice from 'store/slices/eventsSlice';

interface IUseEvents extends RestReduxHook<IEvent, RestRootState, RootState> {
  programSlug?: string;
}

/**
 * A custom hook to handle Events REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IRelationship> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns relationships - array of relationships in the RestRootState reducer
 * @returns reqEvents - makes requests and stores results in the relationshipsReducer
 * @returns getErrorsEvents - gets API errors from the relationshipsReducer
 */
export function useEvents({
  programSlug = '',
  waitForAuthentication = true,
  ...args
}: IUseEvents) {
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);
  const organizationSlug = getSubDomain();
  const { getOrganization } = useSanityOrganization({
    organizationSlug: organizationSlug,
    getSilently: false,
  });
  const organization = getOrganization();
  const organizationName = organization.basic_info.name;
  const goalCategories = organization.goal_categories;

  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();

  const hook: IUseRestReduxResult<IEvent, RestRootStateTypes> = useRestRedux<
    IEvent,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    getSilently: false,
    resultDataName: 'events',
    actions: eventsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });

  const getOrganizationDataForEvent = () => {
    return {
      slug: organizationSlug,
      url: window.location.origin,
      name: organizationName,
      learningHubURL: `${window.location.origin}/learn`,
    };
  };

  const getProgramDataForEvent = () => {
    const programType = program?.program_details?.program_type;
    const programSlug = program?.metadata.id.current;

    return {
      variation: 'individual',
      name: program?.metadata.program_name,
      description: program?.program_details?.program_description,
      slug: programSlug,
      landingPageURL: `${window.location.origin}/programs/${programSlug}`,
      guideRegistrationUrl: `${window.location.origin}/programs/${programSlug}/join/guide`,
      traineeRegistrationUrl: `${window.location.origin}/programs/${programSlug}/join/trainee`,
      type: {
        id: programType?.metadata?.id?.current,
        noun: programType?.program_type_text?.common?.noun,
        verb: programType?.program_type_text?.common?.verb,
        guideSingular: programType?.program_type_text?.common?.guide?.singular,
        guidePluralized:
          programType?.program_type_text?.common?.guide?.pluralized,
        traineeSingular:
          programType?.program_type_text?.common?.trainee?.singular,
        traineePluralized:
          programType?.program_type_text?.common?.trainee?.pluralized,
      },
    };
  };

  const getProgramsDataForEvent = (programs: Program[]) =>
    programs.map((program) => {
      const programType = program?.program_details?.program_type;
      const programSlug = program?.metadata.id.current;

      return {
        variation: 'individual',
        name: program?.metadata.program_name,
        description: program?.program_details?.program_description,
        slug: programSlug,
        landingPageURL: `${window.location.origin}/programs/${programSlug}`,
        guideRegistrationUrl: `${window.location.origin}/programs/${programSlug}/join/guide`,
        traineeRegistrationUrl: `${window.location.origin}/programs/${programSlug}/join/trainee`,
        type: {
          id: programType?.metadata?.id?.current,
          noun: programType?.program_type_text?.common?.noun,
          verb: programType?.program_type_text?.common?.verb,
          guideSingular:
            programType?.program_type_text?.common?.guide?.singular,
          guidePluralized:
            programType?.program_type_text?.common?.guide?.pluralized,
          traineeSingular:
            programType?.program_type_text?.common?.trainee?.singular,
          traineePluralized:
            programType?.program_type_text?.common?.trainee?.pluralized,
        },
      };
    });

  const getGoalDataForEvent = (goal: Partial<IGoal>) => {
    const goalCategoryName = goalCategories.goal_categories.find(
      (goalCategory) =>
        goalCategory.goal_category_slug.current === goal.categorySlug,
    )?.goal_category_name;

    return {
      goalName: goal.objective,
      goalCategory: goalCategoryName,
      goalStatus: goal.status,
      goalUrl: `${window.location.origin}/goals/${goal.id}`,
    };
  };

  return {
    ...hook,
    getEvents: hook.getResults,
    reqEvents: hook.request,
    getErrorsEvents: hook.getErrors,
    hasResultsEvents: hook.hasResults,
    getMessageEvents: hook.getMessage,
    getCodeEvents: hook.getCode,
    getIsLoadingEvents: hook.isLoading,
    getIsErrorEvents: hook.isError,
    getIsSuccessEvents: hook.isSuccess,
    getProgramDataForEvent,
    getOrganizationDataForEvent,
    getGoalDataForEvent,
    getProgramsDataForEvent,
  };
}
