import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { ISetting } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import settingsSlice from 'store/slices/settingsSlice';
import { useDatadogContext } from '@guider-global/datadog';

export function useSettings({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<ISetting, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ISetting, RestRootStateTypes> = useRestRedux<
    ISetting,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'settings',
    actions: settingsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });

  const createSettings = (
    data: Pick<ISetting, 'profile' | 'localeCode' | 'timezone'>,
  ) =>
    hook.request({
      method: 'POST',
      url: `/settings`,
      data,
    });

  const updateSettings = (
    settingsId: string,
    data: Pick<ISetting, 'localeCode' | 'timezone'>,
  ) =>
    hook.request({
      method: 'PATCH',
      url: `/settings/${settingsId}`,
      data,
    });

  return {
    getSettings: hook.getResults,
    reqSettings: hook.request,
    createSettings,
    updateSettings,
    getErrorsSettings: hook.getErrors,
    hasResultsSettings: hook.hasResults,
    getMessageSettings: hook.getMessage,
    getCodeSettings: hook.getCode,
    getIsLoadingSettings: hook.isLoading,
    getIsErrorSettings: hook.isError,
    getIsSuccessSettings: hook.isSuccess,
  };
}
