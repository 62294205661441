import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import videoToken from 'store/slices/videoTokenSlice';
import { ITokenVideo } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Video REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IVideo> hook
 * @returns ...hook - all return values via the `useRestRedux<IVideo>()` hook
 * @returns video - array of video in the RestRootState reducer
 * @returns reqVideo - makes requests and stores results in the videoReducer
 * @returns getErrorsVideo - gets API errors from the videoReducer
 */
export function useVideoTokens({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<ITokenVideo, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ITokenVideo, RestRootStateTypes> =
    useRestRedux<ITokenVideo, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'videoToken',
      actions: videoToken.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    videoToken: hook.getResults,
    reqVideoToken: hook.request,
    getErrorsVideoToken: hook.getErrors,
    hasResultsVideoToken: hook.hasResults,
    getMessageVideoToken: hook.getMessage,
    getCodeVideoToken: hook.getCode,
    isLoadingVideoToken: hook.isLoading,
    isErrorVideoToken: hook.isError,
    isSuccessVideoToken: hook.isSuccess,
  };
}
