import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IProfile } from '@guider-global/shared-types';
import profilesSlice from 'store/slices/profilesSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Profiles REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IProfile> hook
 * @returns ...hook - all return values via the `useRestRedux<IProfile>()` hook
 * @returns profiles - array of profiles in the RestRootState reducer
 * @returns reqProfiles - makes requests and stores results in the profilesReducer
 * @returns getErrorsProfiles - gets API errors from the profilesReducer
 */
export function useProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IProfile, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IProfile, RestRootStateTypes> = useRestRedux<
    IProfile,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'profiles',
    actions: profilesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    getProfiles: hook.getResults,
    reqProfiles: hook.request,
    getErrorsProfiles: hook.getErrors,
    hasResultsProfiles: hook.hasResults,
    getMessageProfiles: hook.getMessage,
    getCodeProfiles: hook.getCode,
    isLoadingProfiles: hook.isLoading,
    isErrorProfiles: hook.isError,
    isSuccessProfiles: hook.isSuccess,
  };
}
