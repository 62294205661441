import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMatch } from '@guider-global/shared-types';
import matchesSlice from 'store/slices/matchesSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Matches REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IMatch> hook
 * @returns ...hook - all return values via the `useRestRedux<IMatch>()` hook
 * @returns matches - array of matches in the RestRootState reducer
 * @returns reqMatches - makes requests and stores results in the matchesReducer
 * @returns getErrorsMatches - gets API errors from the matchesReducer
 */
export function useMatches({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMatch, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMatch, RestRootStateTypes> = useRestRedux<
    IMatch,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'matches',
    actions: matchesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    matches: hook.getResults,
    reqMatches: hook.request,
    getErrorsMatches: hook.getErrors,
    hasResultsMatches: hook.hasResults,
    getMessageMatches: hook.getMessage,
    getCodeMatches: hook.getCode,
    isLoadingMatches: hook.isLoading,
    isErrorMatches: hook.isError,
    isSuccessMatches: hook.isSuccess,
  };
}
