import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IGoal } from '@guider-global/shared-types';
import goalsSlice from 'store/slices/goalsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Goals REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IGoal> hook
 * @returns ...hook - all return values via the `useRestRedux<IGoal>()` hook
 * @returns goals - array of goals in the RestRootState reducer
 * @returns reqGoals - makes requests and stores results in the goalsReducer
 * @returns getErrorsGoals - gets API errors from the goalsReducer
 */
export function useGoals({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IGoal, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IGoal, RestRootStateTypes> = useRestRedux<
    IGoal,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'goals',
    actions: goalsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    goals: hook.getResults,
    reqGoals: hook.request,
    getErrorsGoals: hook.getErrors,
    hasResultsGoals: hook.hasResults,
    getMessageGoals: hook.getMessage,
    getCodeGoals: hook.getCode,
    isLoadingGoals: hook.isLoading,
    isErrorGoals: hook.isError,
    isSuccessGoals: hook.isSuccess,
  };
}
