import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IRelationship } from '@guider-global/shared-types';
import relationshipsSlice from 'store/slices/relationshipsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Relationships REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IRelationship> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns relationships - array of relationships in the RestRootState reducer
 * @returns reqRelationships - makes requests and stores results in the relationshipsReducer
 * @returns getErrorsRelationships - gets API errors from the relationshipsReducer
 */
export function useRelationships({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IRelationship, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IRelationship, RestRootStateTypes> =
    useRestRedux<IRelationship, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'relationships',
      actions: relationshipsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    relationships: hook.getResults,
    reqRelationships: hook.request,
    getErrorsRelationships: hook.getErrors,
    hasResultsRelationships: hook.hasResults,
    getMessageRelationships: hook.getMessage,
    getCodeRelationships: hook.getCode,
    isLoadingRelationships: hook.isLoading,
    isErrorRelationships: hook.isError,
    isSuccessRelationships: hook.isSuccess,
  };
}
