import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IUser } from '@guider-global/shared-types';
import usersSlice from 'store/slices/usersSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IUser>()` hook
 * @returns users - array of users in the RestRootState reducer
 * @returns reqUsers - makes requests and stores results in the usersReducer
 * @returns getErrorsUsers - gets API errors from the usersReducer
 */
export function useUsers({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IUser, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IUser, RestRootStateTypes> = useRestRedux<
    IUser,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'users',
    actions: usersSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });

  return {
    ...hook,
    users: hook.getResults,
    reqUsers: hook.request,
    getErrorsUsers: hook.getErrors,
    hasResultsUsers: hook.hasResults,
    getMessageUsers: hook.getMessage,
    getCodeUsers: hook.getCode,
    isLoadingUsers: hook.isLoading,
    isErrorUsers: hook.isError,
    isSuccessUsers: hook.isSuccess,
  };
}
