import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { ISkill } from '@guider-global/shared-types';
import skillsSlice from 'store/slices/skillsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Skills REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<ISkill> hook
 * @returns ...hook - all return values via the `useRestRedux<ISkill>()` hook
 * @returns skills - array of skills in the RestRootState reducer
 * @returns reqSkills - makes requests and stores results in the skillsReducer
 * @returns getErrorsSkills - gets API errors from the skillsReducer
 */
export function useSkills({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<ISkill, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ISkill, RestRootStateTypes> = useRestRedux<
    ISkill,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'skills',
    actions: skillsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    skills: hook.getResults,
    reqSkills: hook.request,
    getErrorsSkills: hook.getErrors,
    hasResultsSkills: hook.hasResults,
    getMessageSkills: hook.getMessage,
    getCodeSkills: hook.getCode,
    isLoadingSkills: hook.isLoading,
    isErrorSkills: hook.isError,
    isSuccessSkills: hook.isSuccess,
  };
}
