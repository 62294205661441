import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { ISession } from '@guider-global/shared-types';
import sessionsSlice from 'store/slices/sessionsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Sessions REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<ISession> hook
 * @returns ...hook - all return values via the `useRestRedux<ISession>()` hook
 * @returns sessions - array of sessions in the RestRootState reducer
 * @returns reqSessions - makes requests and stores results in the sessionsReducer
 * @returns getErrorsSessions - gets API errors from the sessionsReducer
 */
export function useSessions({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<ISession, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ISession, RestRootStateTypes> = useRestRedux<
    ISession,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'sessions',
    actions: sessionsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    sessions: hook.getResults,
    reqSessions: hook.request,
    getErrorsSessions: hook.getErrors,
    hasResultsSessions: hook.hasResults,
    getMessageSessions: hook.getMessage,
    getCodeSessions: hook.getCode,
    isLoadingSessions: hook.isLoading,
    isErrorSessions: hook.isError,
    isSuccessSessions: hook.isSuccess,
  };
}
