import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMembership } from '@guider-global/shared-types';
import membershipsSlice from 'store/slices/membershipsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Memberships REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IMembership> hook
 * @returns ...hook - all return values via the `useRestRedux<IMembership>()` hook
 * @returns memberships - array of memberships in the RestRootState reducer
 * @returns reqMemberships - makes requests and stores results in the membershipsReducer
 * @returns getErrorsMemberships - gets API errors from the membershipsReducer
 */
export function useMemberships({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMembership, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMembership, RestRootStateTypes> =
    useRestRedux<IMembership, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'memberships',
      actions: membershipsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    memberships: hook.getResults,
    reqMemberships: hook.request,
    getErrorsMemberships: hook.getErrors,
    hasResultsMemberships: hook.hasResults,
    getMessageMemberships: hook.getMessage,
    getCodeMemberships: hook.getCode,
    isLoadingMemberships: hook.isLoading,
    isErrorMemberships: hook.isError,
    isSuccessMemberships: hook.isSuccess,
  };
}
