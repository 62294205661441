import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { TSurvey, ISurveyField } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import surveysSlice from 'store/slices/surveysSlice';
import { useDatadogContext } from '@guider-global/datadog';

interface CreateSurveysData
  extends Omit<TSurvey, 'id' | 'user' | 'surveyFields'> {
  surveyFields: Pick<ISurveyField, 'fieldType' | 'fieldSlug' | 'value'>[];
}

/**
 * A custom hook to handle Surveys REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IMembership> hook
 * @returns ...hook - all return values via the `useRestRedux<TSurvey>()` hook
 * @returns surveys - array of surveys in the RestRootState reducer
 * @returns reqSurveys - makes requests and stores results in the surveysReducer
 * @returns getErrorsSurveys - gets API errors from the surveysReducer
 */
export function useSurveys({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<TSurvey, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<TSurvey, RestRootStateTypes> = useRestRedux<
    TSurvey,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'surveys',
    actions: surveysSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });

  const createSurveys = (data: CreateSurveysData) =>
    hook.request({
      method: 'POST',
      url: `/surveys`,
      data,
    });

  return {
    getSurveys: hook.getResults,
    reqSurveys: hook.request,
    createSurveys,
    getErrorsSurveys: hook.getErrors,
    hasResultsSurveys: hook.hasResults,
    getMessageSurveys: hook.getMessage,
    getCodeSurveys: hook.getCode,
    getIsLoadingSurveys: hook.isLoading,
    getIsErrorSurveys: hook.isError,
    getIsSuccessSurveys: hook.isSuccess,
  };
}
